import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import { postDispositionData } from "../../../API/agentApis";
import useCallCustomer from "../../../hooks/useCallCustomer";
import useForm from "../../../hooks/useForm";
import useAgentStore from "../../../store/agent/agentStore";
import useGeneralStore from "../../../store/generalStore";
import DatePicker from "../../../ui/forms/DatePicker";
import { handleNotifications } from "../../../utils/handleNotification";
import PtpConfirm from "./PtpConfirm";
import WantSettlement from "./WantSettlement";
import { showToast } from "../../../utils/handleToast";
import { settlementProcess } from "../CustomerDetails";
import { addMedia } from "../../../apis";

const dispOptions = [
  {
    label: "",
    options: [
      { value: "PTP", label: "Promise To Pay (PTP)" },
      { value: "SETL", label: "Want Settlement (SETL)" },
      { value: "PAID", label: "Fully PAID" },
      { value: "P-PAID", label: "Partially PAID" },
      { value: "RTP", label: "Refuse To Pay (RTP)" },
      { value: "WN", label: "Wrong Number (WN)" },
      { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
      { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
      { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
    ],
  },
  {
    label: "",
    options: [
      { value: "SW", label: "Switch Off (SW)" },
      { value: "RNR", label: "Ringing No Response (RNR)" },
    ],
  },
  {
    label: "",
    options: [
      // { value: "DND", label: "Do Not Disturb (DND)" },
      { value: "BPTP", label: "Broken PTP (BPTP)" },
      { value: "DIS", label: "Dispute (DIS)" },
      { value: "LANG", label: "Language Barrier (LANG)" },
      { value: "DECEASED", label: "Deceased (DECEASED)" },
      { value: "SUR", label: "Surrender (SUR)" },
    ],
  },
];

const flattenedDispOptions = dispOptions?.map(({ options }) => options).flat();

const createDispOptions = (disps = []) => flattenedDispOptions.filter(({ value }) => disps.includes(value));

const findPtpMaxDate = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const nexSeventhDay = new Date(new Date().setDate(today.getDate() + 7));

  return nexSeventhDay < lastDayOfMonth ? nexSeventhDay : lastDayOfMonth;
};

const ptpMaxDate = findPtpMaxDate();

export default function AddDispForm({ data = null }) {
  const showAlert = useGeneralStore((state) => state.open_alert);
  const removeCustomerId = useAgentStore((state) => state.removeCustomerId);
  const reload = useAgentStore((state) => state.reload);
  const [file, setFile] = useState(null);
  const [fileUrl, setFilUrl] = useState(null);

  const [showWantSettlement, setShowWantSettlement] = useState(false);
  const [showPtpConfirmation, setShowPtpConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const { callCustomer, callTypes } = useCallCustomer();

  const [dispForm, handleDispForm, resetDispForm] = useForm({
    disposition: "",
    ptp_amount: "",
    paid_amount: "",
    paid_date: "",
    ptp_date: null,
    remark: "",
    alt_contact_number: "",
  });

  // const history = useHistory();

  const resetFields = () => {
    setShowPtpConfirmation(false);
    setShowWantSettlement(false);
    setLoading(false);
    resetDispForm();
  };

  const addDisposition = async () => {
    if (dispForm?.disposition === "PTP" && dispForm?.ptp_date > ptpMaxDate) {
      showToast({ message: `Grace period cannot be given beyond ${format(new Date(ptpMaxDate), "dd-MM-yyyy")}`, variant: "warning" });
      return;
    }
    setLoading(true);

    const { data: customerData } = data;
    let params = {
      customer: customerData?.customer_id,
      loan_account_no: !!customerData?.loan_account_no ? customerData?.loan_account_no : null,
      batch_id: customerData?.batch ? customerData?.batch : null,
      product_name: customerData?.model ? customerData?.model : null,
      due_agmt_no: customerData?.due_agmt_no ? customerData?.due_agmt_no : null,
      customer_name: customerData?.customer_name ? customerData?.customer_name : null,
      mobile_number: customerData?.customer_mobile_number ? customerData?.customer_mobile_number : null,
      due_amount: customerData?.total_due ? customerData?.total_due : 0,
      payment_mode: customerData?.payment_type ? customerData?.payment_type : null,
      disposition: dispForm?.disposition,
      alt_contact_number: dispForm.alt_contact_number ? dispForm.alt_contact_number : null,
      remark: dispForm.remark ? dispForm.remark : null,
      ptp_amount: dispForm.ptp_amount ? Number(dispForm.ptp_amount) : 0,
      ptp_date: dispForm.ptp_date ? format(new Date(dispForm.ptp_date), "yyyy-MM-dd") : null,
      paid_amount: dispForm.paid_amount ? Number(dispForm.paid_amount) : 0,
      paid_date: dispForm.paid_date ? format(new Date(dispForm.paid_date), "yyyy-MM-dd") : null,
      settlement_amount: null,
      number_of_emi: 0,
      settlement_emi_data: null,

      contact_date: format(new Date(), "yyyy-MM-dd"),
      agency_name: "The Medius",
      channel: "HUMAN_CALL",
      idle_time: 0,
      chance_of_payment: 0,
    };

    if ((dispForm?.disposition === "PAID" || dispForm?.disposition === "P-PAID") && file) {
      let formData = new FormData();
      formData.append("file", file);
      const mediaResponse = await addMedia(formData);
      if (mediaResponse?.success && !!mediaResponse?.data?.data) {
        let res = await postDispositionData({
          ...params,
          file: mediaResponse?.data?.data,
        });

        if (res) {
          let message = handleNotifications({
            customer_mobile_number: data?.data?.customer_mobile_number,
            message: res?.success_message,
            notification_type: res?.notification_type,
            callCustomer: () => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: data?.data?.customer_mobile_number }),
          });

          // showAlert({ variant: "info", msg: res?.success_message });
          showAlert({
            variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
            msg: message,
          });
          resetDispForm();
          removeCustomerId();
          reload();

          // history.goBack();
        } else showAlert({ variant: "danger", msg: "Failed to Add Disposition" });
        setLoading(false);
      }
    } else {
      let res = await postDispositionData(params);
      if (res) {
        let message = handleNotifications({
          customer_mobile_number: data?.data?.customer_mobile_number,
          message: res?.success_message,
          notification_type: res?.notification_type,
          callCustomer: () => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: data?.data?.customer_mobile_number }),
        });

        // showAlert({ variant: "info", msg: res?.success_message });
        showAlert({
          variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
          msg: message,
        });
        resetDispForm();
        removeCustomerId();
        reload();

        // history.goBack();
      } else showAlert({ variant: "danger", msg: "Failed to Add Disposition" });
      setLoading(false);
    }
  };

  useEffect(() => {
    resetFields();
  }, []);

  const [objective, dipositionOptions] = useMemo(() => {
    switch (data?.settlement_intention) {
      
      case settlementProcess.LETTER_RECEIVED:
        return ["Collect payment", createDispOptions(["PTP", "RTP", "PAID", "P-PAID"])];

      default:
        return ["", dispOptions];
    }
  }, [data?.settlement_intention]);

  return (
    <>
      <div className="item-cl bg-white p-3 rounded-8 h-100">
        <div className="item-cl-head">
          <h6 className="block-title space-mb-16 font-bold">Add Disposition</h6>
          {!!objective && <p className="alert alert-info text-primary px-2 py-1">{objective}</p>}
          <div className="row-field">
            <div className="filter-label space-mb-8">Disposition</div>
            <Select
              placeholder="Select"
              isClearable={true}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  padding: "0.2rem 0",
                  paddingLeft: "0.4rem",
                  border: "1px solid #dddddd !important",
                  borderRadius: "6px",
                  backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
                  color: !state.isSelected && state.isDisabled ? "#aaa" : "#555",
                  zIndex: "999",
                }),
              }}
              options={dipositionOptions}
              value={flattenedDispOptions?.find((el) => el?.value === dispForm?.disposition) ?? ""}
              onChange={(val) => handleDispForm("disposition", val?.value ?? "")}
              components={{
                Group: (props) => (
                  <div style={{ borderBottom: `1px solid #e0e0e0` }}>
                    <components.Group {...props} />
                  </div>
                ),
              }}
            />
          </div>

          {["PTP"].includes(dispForm?.disposition) && (
            <div className="row-field space-mt-16">
              <div className="filter-label space-mb-8">Amount</div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={dispForm?.ptp_amount}
                onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("ptp_amount", e.target.value) : {})}
              />
            </div>
          )}

          {["PAID", "P-PAID"].includes(dispForm?.disposition) && (
            <>
              <div className="row-field space-mt-16">
                <div className="filter-label space-mb-8">Amount</div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Amount"
                  value={dispForm?.paid_amount}
                  onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("paid_amount", e.target.value) : {})}
                />
              </div>
              <div className="row-field space-mt-16 mb-3">
                <div className="filter-label space-mb-8">Date</div>
                <DatePicker
                  label="Select Date"
                  value={dispForm?.paid_date}
                  onChange={(val) => handleDispForm("paid_date", val)}
                  minDate={new Date()}
                  maxDate={ptpMaxDate}
                />
              </div>
              <div className="row-field space-mt-16 mb-3">
                <div className="filter-label space-mb-8">Upload Paid Receipt</div>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    if (e?.target?.files[0]?.size > 1e7) {
                      e.target.value = "";
                      showAlert({ variant: "danger", msg: "File size should be lesser than 10 MBs!" });
                      return;
                    }
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
            </>
          )}

          {["WN"].includes(dispForm?.disposition) && (
            <div className="row-field space-mt-16">
              <div className="filter-label space-mb-8">Alternate Number</div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone Number"
                value={dispForm?.alt_contact_number}
                minLength={10}
                maxLength={10}
                onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("alt_contact_number", e.target.value) : {})}
              />
            </div>
          )}

          {["RTP"].includes(dispForm?.disposition) && (
            <div className="row-field space-mt-16">
              <div className="filter-label space-mb-8">Reason</div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Reason"
                value={dispForm?.remark}
                onChange={(e) => handleDispForm("remark", e.target.value)}
              />
            </div>
          )}

          {["PTP"].includes(dispForm?.disposition) && (
            <div className="row-field space-mt-16">
              <div className="filter-label space-mb-8">Date</div>
              <DatePicker
                label="Select Date"
                value={dispForm?.ptp_date}
                onChange={(val) => handleDispForm("ptp_date", val)}
                // only for ptp data
                minDate={new Date()}
                maxDate={ptpMaxDate}
              />
            </div>
          )}
        </div>

        {!["SETL", "PTP"].includes(dispForm?.disposition) && (
          <button
            disabled={!dispForm?.disposition || loading}
            onClick={() => addDisposition()}
            className={`submit-toggle ${(!dispForm?.disposition || loading) && `disabled-btn`}`}
          >
            {loading ? "Saving..." : "Save Disposition"}
          </button>
        )}

        {["SETL"].includes(dispForm?.disposition) && (
          <button onClick={() => setShowWantSettlement(true)} className="submit-toggle">
            Add Disposition
          </button>
        )}

        {["PTP"].includes(dispForm?.disposition) && (
          <button
            disabled={!dispForm?.ptp_amount || !dispForm?.ptp_date}
            onClick={() => setShowPtpConfirmation(true)}
            className={`submit-toggle ${(!dispForm?.ptp_amount || !dispForm?.ptp_date) && `disabled-btn`}`}
          >
            Add Disposition
          </button>
        )}
      </div>

      <WantSettlement show={showWantSettlement} setShow={setShowWantSettlement} data={data} disposition={dispForm?.disposition} reset={resetDispForm} />

      <PtpConfirm
        data={data}
        show={showPtpConfirmation}
        setShow={setShowPtpConfirmation}
        onConfirm={() => addDisposition()}
        amount={dispForm?.ptp_amount}
        date={dispForm?.ptp_date}
      />
    </>
  );
}
