import React, { useCallback, useEffect, useState } from "react";
import classes from "./style.module.css";
import { sidebarData, DailyOpsSidebar, Chatpanelsidebr, settlementsidebr, Hrsidebr, Agentsidebr, CallQualitySidebar } from "./data";
import { NavLink, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useAuthStore from "../../../store/authStore";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState([]);
  const userdata = useAuthStore((state) => state.userData);
  console.log(userdata, "userdata");

  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const param = searchParams.get("q");

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const onMenuClick = useCallback((index) => {
    setActiveMenu((prev) => (prev.includes(index) ? [] : [index]));
  }, []);
  

  useEffect(() => {
    const findActiveMenuIndex = (sidebarItems) => {
      return sidebarItems.findIndex((sidebarItem) =>
        sidebarItem.items.some((item) => item.href === location.pathname)
      );
    };

    if (userdata?.user_id) {
      const activeIndex = userdata.user_id === 1014
        ? findActiveMenuIndex(DailyOpsSidebar)
        : findActiveMenuIndex(sidebarData);

      if (activeIndex !== -1) {
        setActiveMenu((prev) => {
          if (!prev.includes(activeIndex)) {
            return [...prev, activeIndex];
          }
          return prev;
        });
      }
    }
  }, [location.pathname, userdata?.user_id]);

  const renderMenuItems = (items, index) =>
    items.map(({ label: menuLabel, icon: Icon, href }, menuIndex) => (
      <NavLink
        exact
        key={`menu-${index}-${menuIndex}`}
        to={param === "hidden" ? `${href}?q=${param}` : href}
        activeClassName={classes.active}
        className={classes.menu_item}
        data-tip
        data-for={`menu-tooltip-${index}-${menuIndex}`}
      >
        <span className={classes.menu_item_icon}>
          <Icon />
        </span>
        {!isCollapsed && <span className={classes.menu_item_label}>{menuLabel}</span>}
        <ReactTooltip delayShow={200} effect="solid" place="right" className="py-1 px-2" id={`menu-tooltip-${index}-${menuIndex}`}>
          {menuLabel}
        </ReactTooltip>
      </NavLink>
    ));

  const renderMenu = (menu, index) => {
    if (!menu || typeof menu !== "object" || !menu.label || !Array.isArray(menu.items)) {
      console.error("Invalid menu structure:", menu);
      return null;
    }

    if (menu.label === "Hide Modules" && param !== "hidden") {
      return null;
    }

    return (
      <div key={index} className={classes.menu}>
        {!isCollapsed && (
          <div onClick={() => onMenuClick(index)} className={classes.menu_label}>
            {menu.label}
            <svg
              className={`${classes.menu_collapse_arrow} ${activeMenu.includes(index) && classes.active}`}
              viewBox="0 0 8 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.36873 0.662141C7.55533 0.8657 7.54158 1.18198 7.33802 1.36858L1.72297 6.51571L7.35371 12.1464C7.54897 12.3417 7.54897 12.6583 7.35371 12.8536C7.15845 13.0488 6.84187 13.0488 6.6466 12.8536L0.277344 6.4843L6.66229 0.631426C6.86585 0.44483 7.18214 0.458581 7.36873 0.662141Z"
              />
            </svg>
          </div>
        )}
        <div
          style={{ "--sidebar-menu-item-count": `${menu.items.length}` }}
          className={`${classes.menu_content} ${(activeMenu.includes(index) || isCollapsed) && classes.active}`}
        >
          {renderMenuItems(menu.items, index)}
        </div>
      </div>
    );
  };

  const getSidebarToRender = () => {
    let sidebarItems = [];

    if (Array.isArray(userdata?.groups)) {
      if (userdata.groups.includes("all")) {
        return sidebarData;
      }

      if (userdata.groups.includes("chat")) {
        sidebarItems = sidebarItems.concat(Chatpanelsidebr);
      }
      if (userdata.groups.includes("call_quality")) {
        sidebarItems = sidebarItems.concat(DailyOpsSidebar);
      }
      if (userdata.groups.includes("hr")) {
        sidebarItems = sidebarItems.concat(Hrsidebr);
      }
      if (userdata.groups.includes("agent")) {
        sidebarItems = sidebarItems.concat(Agentsidebr);
      }
      if (userdata.groups.includes("settlement")) {
        sidebarItems = sidebarItems.concat(settlementsidebr);
      }
      if (userdata.groups.includes("call_quality_admin")) {
        sidebarItems = sidebarItems.concat(CallQualitySidebar);
      }
    }

    return sidebarItems.length > 0 ? sidebarItems : sidebarData;
  };

  return (
    <>
      <div className={`${classes.spacer} ${isCollapsed && classes.collapsed}`} />
      <div className={`${classes.container} ${isCollapsed && classes.collapsed}`}>
        <div onClick={toggleCollapse} className={classes.logo_container}>
          <div className={classes.logo}>
            <svg viewBox="0 0 162 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M69.9597 0.20166L53.629 0.403273L100.806 99.3952H117.54L69.9597 0.20166Z" fill="#3296BB" />
              <path d="M41.129 13.5081L0 99.7984H16.7339L50 29.8388L41.129 13.5081Z" fill="#3296BB" />
              <path d="M113.911 0L97.5806 0.201613L144.96 100H161.492L113.911 0Z" fill="#3F9E7B" />
            </svg>
          </div>
          {!isCollapsed && (
            <>
              <div className={classes.branding}>
                <h1>Medius AI</h1>
                <p>Admin</p>
              </div>
              <svg className={classes.collapse_arrow} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.36873 0.662141C7.55533 0.8657 7.54158 1.18198 7.33802 1.36858L1.72297 6.51571L7.35371 12.1464C7.54897 12.3417 7.54897 12.6583 7.35371 12.8536C7.15845 13.0488 6.84187 13.0488 6.6466 12.8536L0.277344 6.4843L6.66229 0.631426C6.86585 0.44483 7.18214 0.458581 7.36873 0.662141Z"
                />
              </svg>
            </>
          )}
        </div>
        <div className={classes.content_container}>{getSidebarToRender().map(renderMenu)}</div>
      </div>
    </>
  );
};

export default Sidebar;

  