import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import useBorrowerInfo from "../store";

export default function Addresses() {
  const [isOpen, setIsOpen] = useState(false);

  const data = useBorrowerInfo((state) => state.data);
  console.log("addresses", data);
  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      <a onClick={toggle} style={{ color: "#118CEC" }} className="fw-medium py-2">
        Addresses
      </a>

      <Offcanvas show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Addresses</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="p-3 pl-5">
            {/* Current Address */}
            {data?.all_addresses?.current_address && data.all_addresses.current_address !== "None" && (
              <div style={{ borderBottom: "1px dashed #ddd" }} className="pb-3">
                <p className="text-black fw-bold mb-2 tx-18">Current Address</p>
                <p className="mb-0 text-black tx-16">
                  {data.all_addresses.current_address}&nbsp;
                  {data?.data?.customer_city && data.data.customer_city !== "None" && `, ${data.data.customer_city}`}
                  {data?.data?.customer_state && data.data.customer_state !== "None" && `, ${data.data.customer_state}`}
                  {data.data?.customer_pincode && data.data.customer_pincode !== "None" && ` ${data.data.customer_pincode}`}
                </p>
              </div>
            )}

            {/* Employer Address */}
            {data?.data?.office_address1 && data.data.office_address1 !== "None" && (
              <div style={{ borderBottom: "1px dashed #ddd" }} className="mt-3 pb-3">
                <p className="text-black fw-bold mb-2 tx-18">Employer Address</p>
                <p className="mb-0 text-black tx-16">
                  {data.data.office_address1}
                  {data?.data?.office_city && data.data.office_city !== "None" && `, ${data.data.office_city}`}
                  {data.data?.office_pincode && data.data.office_pincode !== "None" && `, ${data.data.office_pincode}`}
                  {data?.data?.office_state && data.data.office_state !== "None" && `, ${data.data.office_state}`}
                </p>
              </div>
            )}

            {/* Permanent Address */}
            {data?.all_addresses?.permanent_address && data.all_addresses.permanent_address !== "None" && (
              <div style={{ borderBottom: "1px dashed #ddd" }} className="mt-3 pb-3">
                <p className="text-black fw-bold mb-2 tx-18">Permanent Address</p>
                <p className="mb-0 text-black tx-16">{data.all_addresses.permanent_address}</p>
              </div>
            )}

            {/* Nearest Police Station */}
            <div className="mt-3 pb-3" style={{ borderBottom: "1px dashed #ddd" }}>
              <p className="text-black fw-bold mb-2 tx-18">Nearest Police Station</p>
              <p className="mb-0 text-black tx-16">{data?.data?.nearest_police_station_name ? data?.data?.nearest_police_station_name : "No Data"}</p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
