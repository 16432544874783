import useBorrowerInfo from "./store";
import useGeneralStore from "../../../store/generalStore";
import { BsChevronLeft } from "react-icons/bs";
import Profile from "./components/profile";
import LegalAction from "./components/legal-action";
import Addresses from "./components/addresses";
import { maskString } from "../../../Functions/mask";
import useAuthStore from "../../../store/authStore";
import { isEmpty } from "../../../Functions/isEmpty";
import CallButton from "../../Components/CallButton";
import AddDisposition from "./components/add-disposition";
import OtherActions from "./components/other-actions";
import Analysis from "./components/analysis";
import LoanInfo from "./components/loan-info";
import AmountPaid from "../../Components/CustomerDetails/AmountPaid";
import { downloadMediaUrl } from "../../../API";
import WhatsappResponse from "./components/analysis/whatsapp-response";
import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
export default function BorrowerInfo() {
  const [showWhatsappResponse, setshowWhatsappResponse] = useState(false);
  const isOpen = useBorrowerInfo((state) => state.isOpen);
  const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);
  const userData = useAuthStore((state) => state.userData);

  const close = useBorrowerInfo((state) => state.closeBorrowerInfo);
  const data = useBorrowerInfo((state) => state.data);
  const loading = useBorrowerInfo((state) => state.isLoading);
  const borrowerDetails = data?.data;
  let CONSEQUENCES = 1;
  return (
    <div
      style={{
        inset: "0",
        zIndex: "50",
        paddingTop: "72px",
        paddingLeft: isSidebarOpen ? "178px" : "62px",
        transition: "all ease 0.5s",
        ...(isOpen
          ? { transform: "translateX(0)", opacity: "1", pointerEvents: "auto" }
          : { transform: "translateX(100%)", opacity: "0", pointerEvents: "none" }),
      }}
      className="bg-white position-fixed overflow-x-hidden overflow-y-auto"
    >
      {showWhatsappResponse && <WhatsappResponse show={true} />}
      <div className="position-sticky top-0 py-2 px-3 bg-white">
        <button onClick={close} className="btn btn-primary ps-2 pe-4 d-flex align-items-center">
          <BsChevronLeft /> Back
        </button>
      </div>

      {loading && <p className="text-center p-5 text-muted">Loading...</p>}

      {!loading && (
        <div className="row p-3">
          <div className="col-8 row">
            <div className="col-6 pe-4">
              <div className="d-flex gap-3 mb-2">
                <div className="d-flex flex-column align-items-center gap-1">
                  <div
                    style={{
                      width: "3rem",
                      height: "3rem",
                      background: "#eee",
                    }}
                    className="rounded-circle border overflow-hidden"
                  >
                    {!!data?.client_logo && <img className="w-100 h-100 object-fit-cover" src={data?.client_logo} alt={data?.client_name ?? "Bank"} />}
                  </div>
                  <p style={{ width: "120px", whiteSpace: "wrap", overflow: "hidden", textOverflow: "ellipsis" }} className="m-0 tx-12 fw-bold">
                    {data?.client_name ?? ""}
                  </p>
                </div>
                <div>
                  <p className="mb-0 fw-bold text-black tx-16">{borrowerDetails?.customer_name ?? ""}</p>
                  <p className="mb-0"> {!!userData?.remove_masking ? borrowerDetails?.loan_account_no : maskString(borrowerDetails?.loan_account_no)}</p>
                </div>
              </div>

              <div>
                {/* {!isEmpty(borrowerDetails?.current_bucket) && <p className="mb-0">Bucket : {borrowerDetails?.current_bucket}</p>} */}
                {!isEmpty(borrowerDetails?.case_allocated_date) && <p className="mb-0">Case Allocated Date - {borrowerDetails?.case_allocated_date}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name) && <p className="mb-0">Co Applicant Name - {borrowerDetails?.co_applicant_name}</p>}
                {!isEmpty(borrowerDetails?.co_applicant_name2) && <p className="mb-0">Co Applicant Name 2 - {borrowerDetails?.co_applicant_name2}</p>}
                <AmountPaid data={borrowerDetails} type={false} />
                {!isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <p className="mb-0">
                    Settlement Letter -{" "}
                    <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                      Download
                    </a>
                  </p>
                )}
                {!isEmpty(borrowerDetails?.letter_issue_date) && <p className="mb-0">Setl Letter Issued on - {borrowerDetails?.letter_issue_date}</p>}
                {/* {!isEmpty(borrowerDetails?.email) && <p className="mb-0">Email - {borrowerDetails?.email}</p>} */}
              </div>

              <div
                style={{ borderTop: "1px dashed #ddd", borderBottom: "1px dashed #ddd" }}
                className="d-flex align-items-center justify-content-between gap-3 my-3 py-1"
              >
                <Profile />
                {/* <LegalAction /> */}
                <Addresses />
              </div>

              {/* <div className="d-flex align-items-center justify-content-between gap-2 my-3 py-1 col-md-12">
                <div className="gap-3 mb-3  col-md-6">
                  <AmountPaid data={borrowerDetails} />
                </div>
                {isEmpty(borrowerDetails?.settlement_letter_url) && (
                  <div className="gap-3 mb-3 col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Settlement Letter</span>
                    <span className="item-cl-right text-16 cl-black">
                      <a target="_blank" rel="noreferrer" href={downloadMediaUrl(borrowerDetails?.settlement_letter_url)}>
                        Download
                      </a>
                    </span>
                  </div>
                )}

                {!isEmpty(borrowerDetails?.letter_issue_date) && (
                  <div className="gap-3 mb-3  col-md-6">
                    <span className="item-cl-left text-16 cl-gray-01">Setl Letter Issued on</span>
                    <span className="item-cl-right text-16 cl-black"> {borrowerDetails?.letter_issue_date}</span>
                  </div>
                )}
              </div> */}

              <div className="row">
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <CallButton customer_mobile_number={borrowerDetails?.customer_mobile_number ?? ""} styles={{ width: "100%" }} />
                  <em className="tx-12">{data?.last_call_disposition ?? ""}</em>
                </div>
                <div className="col-6 d-flex gap-1 align-items-center flex-column">
                  <button className="w-100 btn btn-primary" onClick={() => setshowWhatsappResponse(!showWhatsappResponse)}>
                    Whatsapp
                  </button>
                  <em className="tx-12">{data?.last_whatsapp_status ?? ""}</em>
                </div>
              </div>
              <div className="mt-3">
                <span className="font-bold" style={{ fontSize: "medium" }}>
                  CONSEQUENCES
                </span>
                <div className="d-flex gap-1 align-items-start flex-column w-full pt-1">
                  {/* Consequence Item with Tooltip */}
                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {CONSEQUENCES} - Legal Consequences <FaInfoCircle data-tip data-for="legalConsequences" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {(CONSEQUENCES += 1)} - Home & Office Visit <FaInfoCircle data-tip data-for="homeOfficeVisit" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                    <span>
                      {(CONSEQUENCES += 1)} - Willful Defaulter <FaInfoCircle data-tip data-for="willfulDefaulter" style={{ cursor: "pointer" }} />
                    </span>
                    <span>✅</span>
                  </div>

                  <div
                    style={{
                      color: data?.client_id === 51 || data?.client_id === 35 || data?.client_id === 63 ? "red" : "green",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "75%",
                    }}
                  >
                    <span>
                      {(CONSEQUENCES += 1)} - Police Action <FaInfoCircle data-tip data-for="policeAction" style={{ cursor: "pointer" }} />
                    </span>
                    {data?.client_id === 51 || data?.client_id === 35 || data?.client_id === 63 ? <span>❌</span> : <span>✅</span>}
                  </div>

                  {data?.data.pre_approved_sattlement_amount > 0 && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Settlement Final Chance <FaInfoCircle data-tip data-for="settlementFinalChance" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}
                  {data?.data?.settlement_letter_url && data?.data?.paid === false && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Letter Issued, Not Paid <FaInfoCircle data-tip data-for="letterIssued" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}

                  {data?.data?.actual_disposition === "BPTP" && (
                    <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                      <span>
                        {(CONSEQUENCES += 1)} - Broken PTP <FaInfoCircle data-tip data-for="brokenPTP" style={{ cursor: "pointer" }} />
                      </span>
                      <span>✅</span>
                    </div>
                  )}
                  {data?.data?.product_name?.toLowerCase() === "tw" ||
                    (data?.data?.product_name?.toLowerCase() === "two wheeler" && (
                      <div style={{ color: "green", display: "flex", justifyContent: "space-between", width: "75%" }}>
                        <span>
                          {(CONSEQUENCES += 1)} - Vehicle Repossession <FaInfoCircle data-tip data-for="bikerepossession" style={{ cursor: "pointer" }} />
                        </span>
                        <span>✅</span>
                      </div>
                    ))}

                  {/* Tooltips */}
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="legalConsequences">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Legal Consequences</strong>
                      <ul>
                        <li>Pay today - No Grace Period</li>
                        <li>Appoint Lawyer - Reply to Notice</li>
                        <li>Conciliation Hearing - 30k Penalty</li>
                        <li>CIBIL Score - Will Never Get Loan</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="homeOfficeVisit">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Home & Office Visit</strong>
                      <ul>
                        <li>Home Visit & Legal Notice</li>
                        <li>Office Visit & Legal Notice</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="willfulDefaulter">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Willful Defaulter</strong>
                      <ul>
                        <li>All Assets Known to Government</li>
                        <li>Reporting to RBI - Willful Defaulter</li>
                        <li>If Salaried - Garnishee Order</li>
                        <li>If Business - Bank A/c Freeze</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="policeAction">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Police Action</strong>
                      <ul>
                        {data?.client_id === 51 || data?.client_id === 35 ? (
                          <li>Do not speak about Police Action</li>
                        ) : (
                          <>
                            <li>FIR & Arrest Warrant</li>
                            <li>NACH Bounce - Section 25</li>
                            <li>New Law - Bharatiya Nyaya Sanhita</li>
                            <li>Action Under Section 316</li>
                            <li>Action Under Section 318</li>
                            <li>Action Under Section 323</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="settlementFinalChance">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Settlement Final Chance</strong>
                      <ul>
                        <li>OTS - One Time Settlement Offer</li>
                        <li>No Waiver Negotiation</li>
                        <li>Pay settlement in 24 Hours</li>
                        <li>Else pay full amount</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="letterIssued">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Letter Issued, Not Paid</strong>
                      <ul>
                        <li>Everything Is Taken on Record</li>
                        <li>Whatsapp Read & IP Address Tracked</li>
                        <li>Deadline for Payment Has Crossed</li>
                        <li>Offer Will Be Withdrawn Permanently</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="brokenPTP">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Broken PTP</strong>
                      <ul>
                        <li>Everything Is Taken on Record</li>
                        <li>Deadline for Payment Has Crossed</li>
                        <li>Whatsapp Read & IP Address Tracked</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                  <ReactTooltip effect="solid" place="right" className="py-2 px-3" id="bikerepossession">
                    <div style={{ fontWeight: "normal" }}>
                      <strong>Vehicle Repossession</strong>
                      <ul>
                        <li>Surrender Vehicle, if unable to repay.</li>
                        <li>Vehicle Repossession Notice issued.</li>
                        <li>SARFAESI Act, Sections 13 & 14.</li>
                        <li>If already surrendered, to whom & when.</li>
                      </ul>
                    </div>
                  </ReactTooltip>
                </div>
              </div>
            </div>
            <div style={{ borderLeft: "1px dashed #ddd" }} className="col-6 pl-3">
              <LoanInfo />
            </div>

            <div className="col-12 py-4" />

            <Analysis info={data} />
          </div>

          <div className="col-4 bg-light rounded p-3">
            <div className="bg-white h-100 rounded p-3 d-flex flex-column">
              <AddDisposition />
              <div className="h-0 w-full my-4" style={{ borderTop: "1px dashed #ddd" }} />
              <OtherActions />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
